import * as React from "react";
import { Link } from "gatsby";
import { INotFoundProps } from "../pageHelpers/NotFound/NotFoundProps";
import { Message, StyledBackground, StyledRow, StyledContainer, StyledButton } from "../pageHelpers/NotFound/NotFoundHelpers";
import Layout from "../components/global/Layout/Layout";
import Title2 from "../components/global/Title2/Title2";
import SubTitle from "../components/global/SubTitle/SubTitle";
import SEO from "../components/global/SEO/SEO";


const NotFoundPage = (props: INotFoundProps) => {
  return (
    <Layout hideSocialBar={true}>
      <SEO title="404: Page Not Found" />
      <StyledContainer>
        <StyledRow>
          <div className="title">Destination Unknown.</div>
          <div className="subTitle">Sorry, we can't find the page you are looking for.</div>
          <StyledButton>Back To Homepage</StyledButton>
        </StyledRow>
      </StyledContainer>
    </Layout>);
};

export default NotFoundPage;
