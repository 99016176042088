import * as React from "react";
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Button } from "react-bootstrap";
import { styledContainer, message, messageContainer, logo, container, backButton } from "./NotFound.module.scss";
import { StaticImage } from "gatsby-plugin-image";

export const StyledContainer = (props: any) => {
    return (<Container className={`${styledContainer} h-100 d-flex flex-column justify-content-center overflow-auto pt-4 pb-4`}>{props.children}</Container>);
};
export const Logo = (props: any) => {
    return (<StaticImage src={"../../images/favicon-32x32.png"} 
        alt="Travel Pass" 
        className={logo}
        placeholder="blurred"
        layout="fixed"
        width={84}
        />);
};
export const Message = (props: any) => {
    return (<p className={message}>{props.children}</p>);
};
export const StyledRow = (props: any) => {
    return (<Row className={`text-center text-white ${messageContainer}`}>{props.children}</Row>);
};
export const StyledBackground = (props: any) => {
    
    const { bgImage } = useStaticQuery(
        graphql`
            query {
                bgImage: file(relativePath: { eq: "404-Background.png" }) {
                childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                }
            }
            }
        `
        );
    const _image = getImage(bgImage);
    const _bgImage = convertToBgImage(_image);
    return (<BackgroundImage {..._bgImage} 
        preserveStackingContext
        className={`${container} bg-dark position-fixed fixed-bottom fixed-top p-0`}>{props.children}</BackgroundImage>);
};

export const StyledButton = (props: any) => {
    return ( <Link to="/"><Button className={backButton} {...props}> {props.children}  </Button></Link>);
};

